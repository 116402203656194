.uiCard {
	margin-top: 0px;
}
.cardTitle {
	z-index: 1;
	position: absolute;
	right: 1rem;
}
.rightImage {
	max-width: 40px;
	position: absolute;
	top: 10px;
	right: 10px;
}
