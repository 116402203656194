ion-input.weaponInput {
	text-align: end;
	color: rgb(0, 190, 0);
	width: 50px;
	/* background-color: rgb(69, 69, 69); */
	--placeholder-color: rgb(80, 80, 80);
	--placeholder-opacity: 1;
	--placeholder-font-style: italic;
}

.native-input[disabled] {
	color: #fdd000;
	opacity: 1 !important;
}

.select-disabled[disabled] {
	color: #fdd000;
	opacity: 1 !important;
}

/* Popover Interface: set color for the popover using Item's CSS variables */
.my-custom-interface .select-interface-option {
	--color: #971e49;
	--color-hover: #79193b;
}

/* Action Sheet Interface: set color for the action sheet using its button CSS variables */
.my-custom-interface .select-interface-option {
	--button-color: #971e49;
	--button-color-hover: #79193b;
}

/* Alert Interface: set color for alert options (single selection) */
.my-custom-interface .select-interface-option .alert-radio-label {
	color: #971e49;
}

/* Alert Interface: set color for alert options (multiple selection) */
.my-custom-interface .select-interface-option .alert-checkbox-label {
	color: #971e49;
}

/* Alert Interface: set color for checked alert options (single selection) */
.my-custom-interface
	.select-interface-option[aria-checked="true"]
	.alert-radio-label {
	color: #79193b;
}

/* Alert Interface: set color for checked alert options (multiple selection) */
.my-custom-interface
	.select-interface-option[aria-checked="true"]
	.alert-checkbox-label {
	color: #79193b;
}
/* Popover Interface: set color for the popover using Item's CSS variables */
.my-custom-interface .brown-option {
	--color: #ffffff;
	--color-hover: #8d8d8d;
}
