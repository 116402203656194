ion-input.gearInput {
	text-align: end;
	color: rgb(0, 190, 0);
	width: 50px;
	/* background-color: rgb(69, 69, 69); */
	--placeholder-color: rgb(80, 80, 80);
	--placeholder-opacity: 1;
	--placeholder-font-style: italic;
}

.native-input[disabled] {
	color: #fdd000;
	opacity: 1 !important;
}

.select-disabled[disabled] {
	color: #fdd000;
	opacity: 1 !important;
}

.gearSelect {
	text-align: end;
}
.gearSelectNamed {
	color: #ffd000;
	text-align: end;
}
.gearInput {
	text-align: end;
}
.gearInputNamed {
	color: #ffd000;
	text-align: end;
}
/* Popover Interface: set color for the popover using Item's CSS variables */
.my-custom-interface .select-interface-option {
	--color: #971e49;
	--color-hover: #79193b;
}

/* Action Sheet Interface: set color for the action sheet using its button CSS variables */
.my-custom-interface .select-interface-option {
	--button-color: #971e49;
	--button-color-hover: #79193b;
}

/* Alert Interface: set color for alert options (single selection) */
.my-custom-interface .select-interface-option .alert-radio-label {
	color: #971e49;
}

/* Alert Interface: set color for alert options (multiple selection) */
.my-custom-interface .select-interface-option .alert-checkbox-label {
	color: #971e49;
}

/* Alert Interface: set color for checked alert options (single selection) */
.my-custom-interface
	.select-interface-option[aria-checked="true"]
	.alert-radio-label {
	color: #79193b;
}

/* Alert Interface: set color for checked alert options (multiple selection) */
.my-custom-interface
	.select-interface-option[aria-checked="true"]
	.alert-checkbox-label {
	color: #79193b;
}
/* Popover Interface: set color for the popover using Item's CSS variables */
.my-custom-interface .brown-option {
	--color: #ffffff;
	--color-hover: #8d8d8d;
}

.gear-card-header {
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}

/* BRANDS */
.brand-header {
	height: 80px;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-overlord-armaments {
	/* composes: brand-header; */
	background-image: url("/src/assets/images/brands/brand-overlord-armaments.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-aces-eights {
	background-image: url("/src/assets/images/brands/brand-aces-eights.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-gila-guard {
	background-image: url("/src/assets/images/brands/brand-gila-guard.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-sokolov-concern {
	background-image: url("/src/assets/images/brands/brand-sokolov-concern.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-true-patriot {
	background-image: url("/src/assets/images/brands/brand-true-patriot.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-walker-harris-co {
	background-image: url("/src/assets/images/brands/brand-walker-harris-co.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-rigger {
	background-image: url("/src/assets/images/brands/brand-rigger.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-belstone-armory {
	background-image: url("/src/assets/images/brands/brand-belstone-armory.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-ongoing-directive {
	background-image: url("/src/assets/images/brands/brand-ongoing-directive.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-yaahl-gear {
	background-image: url("/src/assets/images/brands/brand-yaahl-gear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-negotiators-dilemma {
	background-image: url("/src/assets/images/brands/brand-negotiators-dilemma.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-alps-summit-armaments {
	background-image: url("/src/assets/images/brands/brand-alps-summit-armaments.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-badger-tuff {
	background-image: url("/src/assets/images/brands/brand-badger-tuff.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-grupo-sombra-sa {
	background-image: url("/src/assets/images/brands/brand-grupo-sombra-sa.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-murakami-industries {
	background-image: url("/src/assets/images/brands/brand-murakami-industries.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-crafted {
	background-image: url("/src/assets/images/brands/brand-crafted.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-ceska-vyroba-sro {
	background-image: url("/src/assets/images/brands/brand-ceska-vyroba-sro.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-petrov-defense-group {
	background-image: url("/src/assets/images/brands/brand-petrov-defense-group.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-richter-kaiser-gmbh {
	background-image: url("/src/assets/images/brands/brand-richter-kaiser-gmbh.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-hard-wired {
	background-image: url("/src/assets/images/brands/brand-hard-wired.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-eclipse-protocol {
	background-image: url("/src/assets/images/brands/brand-eclipse-protocol.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-airaldi-holdings {
	background-image: url("/src/assets/images/brands/brand-airaldi-holdings.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-hana-u-corporation {
	background-image: url("/src/assets/images/brands/brand-hana-u-corporation.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-providence-defense {
	background-image: url("/src/assets/images/brands/brand-providence-defense.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-wyvern-wear {
	background-image: url("/src/assets/images/brands/brand-wyvern-wear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-tip-of-the-spear {
	background-image: url("/src/assets/images/brands/brand-tip-of-the-spear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-511-tactical {
	background-image: url("/src/assets/images/brands/brand-511-tactical.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-douglas-harding {
	background-image: url("/src/assets/images/brands/brand-douglas-harding.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-golan-gear-ltd {
	background-image: url("/src/assets/images/brands/brand-golan-gear-ltd.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-coyotes-mask {
	background-image: url("/src/assets/images/brands/brand-exotic-coyotes-mask.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-vile {
	background-image: url("/src/assets/images/brands/brand-exotic-vile.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-acostas-go-bag {
	background-image: url("/src/assets/images/brands/brand-exotic-acostas-go-bag.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-ridgeways-pride {
	background-image: url("/src/assets/images/brands/brand-exotic-ridgeways-pride.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-tardigrade-armor-system {
	background-image: url("/src/assets/images/brands/brand-exotic-tardigrade-armor-system.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-btsu-gloves {
	background-image: url("/src/assets/images/brands/brand-exotic-btsu-gloves.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-memento {
	background-image: url("/src/assets/images/brands/brand-exotic-memento.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-dodge-city-gunslingers-holster {
	background-image: url("/src/assets/images/brands/brand-exotic-dodge-city-gunslingers-holster.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-imperial-dynasty {
	background-image: url("/src/assets/images/brands/brand-exotic-imperial.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-waveform {
	background-image: url("/src/assets/images/brands/brand-exotic-waveform.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-ninjabike-messenger-kneepads {
	background-image: url("/src/assets/images/brands/brand-exotic-ninjabike-messenger-kneepads.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-exotic-sawyers-kneepads {
	background-image: url("/src/assets/images/brands/brand-exotic-sawyers-kneepads.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-china-light-industries-corporation {
	background-image: url("/src/assets/images/brands/brand-china-light-industries-corporation.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-fenris-group-ab {
	background-image: url("/src/assets/images/brands/brand-fenris-group-ab.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-hunters-fury {
	background-image: url("/src/assets/images/brands/brand-hunters-fury.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-foundry-bulwark {
	background-image: url("/src/assets/images/brands/brand-foundry-bulwark.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-future-initiative {
	background-image: url("/src/assets/images/brands/brand-future-initiative.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-strikers-battlegear {
	background-image: url("/src/assets/images/brands/brand-strikers-battlegear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-system-corruption {
	background-image: url("/src/assets/images/brands/brand-system-corruption.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-empress-international {
	background-image: url("/src/assets/images/brands/brand-empress-international.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.brand-511-tactical {
	background-image: url("/src/assets/images/brands/brand-511-tactical.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

/* HEADER */
.header-gear-mask-overlord-armaments {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-overlord-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-overlord-armaments {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-overlord-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-overlord-armaments {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-overlord-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-overlord-armaments {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-overlord-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-overlord-armaments {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-overlord-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-overlord-armaments {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-overlord-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-aces-eights {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-aces-eights.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-aces-eights {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-aces-eights.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-aces-eights {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-aces-eights.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-aces-eights {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-aces-eights.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-aces-eights {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-aces-eights.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-aces-eights {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-aces-eights.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-gila-guard {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-gila-guard.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-gila-guard {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-gila-guard.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-gila-guard {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-gila-guard.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-gila-guard {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-gila-guard.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-gila-guard {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-gila-guard.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-gila-guard {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-gila-guard.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-sokolov-concern {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-sokolov-concern.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-sokolov-concern {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-sokolov-concern.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-sokolov-concern {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-sokolov-concern.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-sokolov-concern {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-sokolov-concern.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-sokolov-concern {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-sokolov-concern.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-sokolov-concern {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-sokolov-concern.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-true-patriot {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-true-patriot.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-true-patriot {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-true-patriot.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-true-patriot {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-true-patriot.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-true-patriot {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-true-patriot.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-true-patriot {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-true-patriot.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-true-patriot {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-true-patriot.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-walker-harris-co {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-walker-harris-co.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-walker-harris-co {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-walker-harris-co.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-walker-harris-co {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-walker-harris-co.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-walker-harris-co {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-walker-harris-co.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-walker-harris-co {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-walker-harris-co.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-walker-harris-co {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-walker-harris-co.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-rigger {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-rigger.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-rigger {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-rigger.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-rigger {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-rigger.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-rigger {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-rigger.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-rigger {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-rigger.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-rigger {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-rigger.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-belstone-armory {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-belstone-armory.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-belstone-armory {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-belstone-armory.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-belstone-armory {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-belstone-armory.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-belstone-armory {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-belstone-armory.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-belstone-armory {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-belstone-armory.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-belstone-armory {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-belstone-armory.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-ongoing-directive {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-ongoing-directive.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-ongoing-directive {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-ongoing-directive.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-ongoing-directive {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-ongoing-directive.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-ongoing-directive {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-ongoing-directive.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-ongoing-directive {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-ongoing-directive.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-ongoing-directive {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-ongoing-directive.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-yaahl-gear {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-yaahl-gear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-yaahl-gear {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-yaahl-gear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-yaahl-gear {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-yaahl-gear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-yaahl-gear {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-yaahl-gear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-yaahl-gear {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-yaahl-gear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-yaahl-gear {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-yaahl-gear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-negotiators-dilemma {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-negotiators-dilemma.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-negotiators-dilemma {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-negotiators-dilemma.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-negotiators-dilemma {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-negotiators-dilemma.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-negotiators-dilemma {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-negotiators-dilemma.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-negotiators-dilemma {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-negotiators-dilemma.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-negotiators-dilemma {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-negotiators-dilemma.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-alps-summit-armaments {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-alps-summit-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-alps-summit-armaments {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-alps-summit-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-alps-summit-armaments {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-alps-summit-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-alps-summit-armaments {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-alps-summit-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-alps-summit-armaments {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-alps-summit-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-alps-summit-armaments {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-alps-summit-armaments.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-badger-tuff {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-badger-tuff.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-badger-tuff {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-badger-tuff.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-badger-tuff {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-badger-tuff.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-badger-tuff {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-badger-tuff.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-badger-tuff {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-badger-tuff.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-badger-tuff {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-badger-tuff.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-grupo-sombra-sa {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-grupo-sombra-sa.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-grupo-sombra-sa {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-grupo-sombra-sa.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-grupo-sombra-sa {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-grupo-sombra-sa.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-grupo-sombra-sa {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-grupo-sombra-sa.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-grupo-sombra-sa {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-grupo-sombra-sa.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-grupo-sombra-sa {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-grupo-sombra-sa.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-murakami-industries {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-murakami-industries.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-murakami-industries {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-murakami-industries.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-murakami-industries {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-murakami-industries.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-murakami-industries {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-murakami-industries.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-murakami-industries {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-murakami-industries.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-murakami-industries {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-murakami-industries.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-crafted {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-crafted.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-crafted {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-crafted.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-crafted {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-crafted.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-crafted {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-crafted.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-crafted {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-crafted.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-crafted {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-crafted.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-ceska-vyroba-sro {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-ceska-vyroba-sro.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-ceska-vyroba-sro {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-ceska-vyroba-sro.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-ceska-vyroba-sro {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-ceska-vyroba-sro.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-ceska-vyroba-sro {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-ceska-vyroba-sro.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-ceska-vyroba-sro {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-ceska-vyroba-sro.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-ceska-vyroba-sro {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-ceska-vyroba-sro.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-petrov-defense-group {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-petrov-defense-group.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-petrov-defense-group {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-petrov-defense-group.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-petrov-defense-group {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-petrov-defense-group.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-petrov-defense-group {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-petrov-defense-group.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-petrov-defense-group {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-petrov-defense-group.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-petrov-defense-group {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-petrov-defense-group.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-richter-kaiser-gmbh {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-richter-kaiser-gmbh.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-richter-kaiser-gmbh {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-richter-kaiser-gmbh.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-richter-kaiser-gmbh {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-richter-kaiser-gmbh.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-richter-kaiser-gmbh {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-richter-kaiser-gmbh.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-richter-kaiser-gmbh {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-richter-kaiser-gmbh.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-richter-kaiser-gmbh {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-richter-kaiser-gmbh.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-hard-wired {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-hard-wired.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-hard-wired {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-hard-wired.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-hard-wired {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-hard-wired.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-hard-wired {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-hard-wired.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-hard-wired {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-hard-wired.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-hard-wired {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-hard-wired.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-eclipse-protocol {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-eclipse-protocol.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-eclipse-protocol {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-eclipse-protocol.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-eclipse-protocol {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-eclipse-protocol.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-eclipse-protocol {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-eclipse-protocol.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-eclipse-protocol {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-eclipse-protocol.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-eclipse-protocol {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-eclipse-protocol.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-airaldi-holdings {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-airaldi-holdings.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-airaldi-holdings {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-airaldi-holdings.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-airaldi-holdings {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-airaldi-holdings.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-airaldi-holdings {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-airaldi-holdings.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-airaldi-holdings {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-airaldi-holdings.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-airaldi-holdings {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-airaldi-holdings.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-hana-u-corporation {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-hana-u-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-hana-u-corporation {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-hana-u-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-hana-u-corporation {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-hana-u-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-hana-u-corporation {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-hana-u-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-hana-u-corporation {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-hana-u-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-hana-u-corporation {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-hana-u-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-providence-defense {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-providence-defense.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-providence-defense {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-providence-defense.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-providence-defense {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-providence-defense.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-providence-defense {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-providence-defense.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-providence-defense {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-providence-defense.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-providence-defense {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-providence-defense.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-wyvern-wear {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-wyvern-wear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-wyvern-wear {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-wyvern-wear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-wyvern-wear {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-wyvern-wear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-wyvern-wear {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-wyvern-wear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-wyvern-wear {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-wyvern-wear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-wyvern-wear {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-wyvern-wear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-tip-of-the-spear {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-tip-of-the-spear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-tip-of-the-spear {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-tip-of-the-spear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-tip-of-the-spear {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-tip-of-the-spear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-tip-of-the-spear {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-tip-of-the-spear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-tip-of-the-spear {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-tip-of-the-spear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-tip-of-the-spear {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-tip-of-the-spear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-511-tactical {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-511-tactical.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-511-tactical {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-511-tactical.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-511-tactical {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-511-tactical.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-511-tactical {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-511-tactical.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-511-tactical {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-511-tactical.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-511-tactical {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-511-tactical.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-douglas-harding {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-douglas-harding.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-douglas-harding {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-douglas-harding.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-douglas-harding {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-douglas-harding.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-douglas-harding {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-douglas-harding.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-douglas-harding {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-douglas-harding.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-douglas-harding {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-douglas-harding.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-golan-gear-ltd {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-golan-gear-ltd.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-golan-gear-ltd {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-golan-gear-ltd.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-golan-gear-ltd {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-golan-gear-ltd.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-golan-gear-ltd {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-golan-gear-ltd.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-golan-gear-ltd {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-golan-gear-ltd.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-golan-gear-ltd {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-golan-gear-ltd.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-exotic-coyotes-mask {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-exotic-coyotes-mask.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-exotic-vile {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-exotic-vile.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-exotic-memento {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-exotic-memento.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-exotic-acostas-go-bag {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-exotic-acostas-go-bag.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-exotic-tardigrade-armor-system {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-exotic-tardigrade-armor-system.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-exotic-btsu-gloves {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-exotic-btsu-gloves.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-exotic-dodge-city-gunslingers-holster {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-exotic-dodge-city-gunslingers-holster.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-exotic-ninjabike-messenger-kneepads {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-exotic-ninjabike-messenger-kneepads.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-exotic-sawyers-kneepads {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-exotic-sawyers-kneepads.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-china-light-industries-corporation {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-china-light-industries-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-china-light-industries-corporation {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-china-light-industries-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-china-light-industries-corporation {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-china-light-industries-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-china-light-industries-corporation {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-china-light-industries-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-china-light-industries-corporation {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-china-light-industries-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-china-light-industries-corporation {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-china-light-industries-corporation.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-fenris-group-ab {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-fenris-group-ab.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-fenris-group-ab {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-fenris-group-ab.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-fenris-group-ab {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-fenris-group-ab.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-fenris-group-ab {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-fenris-group-ab.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-fenris-group-ab {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-fenris-group-ab.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-fenris-group-ab {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-fenris-group-ab.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-hunters-fury {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-hunters-fury.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-hunters-fury {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-hunters-fury.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-hunters-fury {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-hunters-fury.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-hunters-fury {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-hunters-fury.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-hunters-fury {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-hunters-fury.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-hunters-fury {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-hunters-fury.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-foundry-bulwark {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-foundry-bulwark.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-foundry-bulwark {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-foundry-bulwark.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-foundry-bulwark {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-foundry-bulwark.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-foundry-bulwark {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-foundry-bulwark.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-foundry-bulwark {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-foundry-bulwark.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-foundry-bulwark {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-foundry-bulwark.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-future-initiative {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-future-initiative.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-future-initiative {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-future-initiative.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-future-initiative {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-future-initiative.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-future-initiative {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-future-initiative.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-future-initiative {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-future-initiative.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-future-initiative {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-future-initiative.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-strikers-battlegear {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-strikers-battlegear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-strikers-battlegear {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-strikers-battlegear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-strikers-battlegear {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-strikers-battlegear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-strikers-battlegear {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-strikers-battlegear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-strikers-battlegear {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-strikers-battlegear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-strikers-battlegear {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-strikers-battlegear.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-system-corruption {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-system-corruption.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-system-corruption {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-system-corruption.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-system-corruption {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-system-corruption.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-system-corruption {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-system-corruption.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-system-corruption {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-system-corruption.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-system-corruption {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-system-corruption.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-mask-empress-international {
	background-image: url("/src/assets/images/mask.png"),
		url("/src/assets/images/brands/brand-empress-international.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-backpack-empress-international {
	background-image: url("/src/assets/images/backpack.png"),
		url("/src/assets/images/brands/brand-empress-international.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-vest-empress-international {
	background-image: url("/src/assets/images/vest.png"),
		url("/src/assets/images/brands/brand-empress-international.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-gloves-empress-international {
	background-image: url("/src/assets/images/gloves.png"),
		url("/src/assets/images/brands/brand-empress-international.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-holster-empress-international {
	background-image: url("/src/assets/images/holster.png"),
		url("/src/assets/images/brands/brand-empress-international.png");
	background-position: right, left;
	background-size: contain, contain;
}
.header-gear-kneepads-empress-international {
	background-image: url("/src/assets/images/kneepads.png"),
		url("/src/assets/images/brands/brand-empress-international.png");
	background-position: right, left;
	background-size: contain, contain;
}
