ion-alert.two-exotics {
	--backdrop-opacity: 0.9;
}

.two-exotics .alert-head {
	background-color: transparent;
}

.two-exotics .alert-title {
	color: crimson;
	text-align: center;
}

.two-exotics .alert-sub-title {
	color: crimson !important;
	text-align: center;
}

.two-exotics .alert-button-group {
	padding: 8px;
}

button.alert-button.alert-button-confirm {
	background-color: var(--ion-color-success);
	color: var(--ion-color-success-contrast);
}

.md button.alert-button.alert-button-confirm {
	border-radius: 4px;
}

.ios .two-exotics button.alert-button {
	border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}

.ios button.alert-button.alert-button-cancel {
	border-right: 0;
	border-bottom-left-radius: 13px;
	border-top-left-radius: 13px;
}

.ios button.alert-button.alert-button-confirm {
	border-bottom-right-radius: 13px;
	border-top-right-radius: 13px;
}
