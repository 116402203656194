ion-col {
	border: 2px solid rgb(0, 0, 0);
	text-align: center;
}

.ionColBorder {
	border: 1px solid grey;
	text-align: center;
}

ion-modal {
	/* --height: 60%;
	--width: 60%; */
	--border-radius: 16px;
	--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
		0 4px 6px -4px rgb(0 0 0 / 0.1);
}
