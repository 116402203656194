.brandoverlordarmaments {
	background-image: url("../../assets/images/brands/brand-overlord-armaments.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandaceseights {
	background-image: url("../../assets/images/brands/brand-aces-eights.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandgilaguard {
	background-image: url("../../assets/images/brands/brand-gila-guard.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandsokolovconcern {
	background-image: url("../../assets/images/brands/brand-sokolov-concern.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandtruepatriot {
	background-image: url("../../assets/images/brands/brand-true-patriot.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandwalkerharrisco {
	background-image: url("../../assets/images/brands/brand-walker-harris-co.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandrigger {
	background-image: url("../../assets/images/brands/brand-rigger.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandbelstonearmory {
	background-image: url("../../assets/images/brands/brand-belstone-armory.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandongoingdirective {
	background-image: url("../../assets/images/brands/brand-ongoing-directive.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandyaahlgear {
	background-image: url("../../assets/images/brands/brand-yaahl-gear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandnegotiatorsdilemma {
	background-image: url("../../assets/images/brands/brand-negotiators-dilemma.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandalpssummitarmaments {
	background-image: url("../../assets/images/brands/brand-alps-summit-armaments.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandbadgertuff {
	background-image: url("../../assets/images/brands/brand-badger-tuff.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandgruposombrasa {
	background-image: url("../../assets/images/brands/brand-grupo-sombra-sa.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandmurakamiindustries {
	background-image: url("../../assets/images/brands/brand-murakami-industries.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandcrafted {
	background-image: url("../../assets/images/brands/brand-crafted.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandceskavyrobasro {
	background-image: url("../../assets/images/brands/brand-ceska-vyroba-sro.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandpetrovdefensegroup {
	background-image: url("../../assets/images/brands/brand-petrov-defense-group.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandrichterkaisergmbh {
	background-image: url("../../assets/images/brands/brand-richter-kaiser-gmbh.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandhardwired {
	background-image: url("../../assets/images/brands/brand-hard-wired.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandeclipseprotocol {
	background-image: url("../../assets/images/brands/brand-eclipse-protocol.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandairaldiholdings {
	background-image: url("../../assets/images/brands/brand-airaldi-holdings.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandhana-ucorporation {
	background-image: url("../../assets/images/brands/brand-hana-u-corporation.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandprovidencedefense {
	background-image: url("../../assets/images/brands/brand-providence-defense.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandwyvernwear {
	background-image: url("../../assets/images/brands/brand-wyvern-wear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandtipofthespear {
	background-image: url("../../assets/images/brands/brand-tip-of-the-spear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand511tactical {
	background-image: url("../../assets/images/brands/brand-511-tactical.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.branddouglasharding {
	background-image: url("../../assets/images/brands/brand-douglas-harding.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandgolangearltd {
	background-image: url("../../assets/images/brands/brand-golan-gear-ltd.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
/* .brandexotic {
	background-image: url("../../assets/images/brands/brand-exotic.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
} */
.brandexoticdodgecitygunslingersholster {
	background-image: url("../../assets/images/brands/brand-exotic-dodge-city-gunslingers-holster.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandchinalightindustriescorporation {
	background-image: url("../../assets/images/brands/brand-china-light-industries-corporation.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandfenrisgroupab {
	background-image: url("../../assets/images/brands/brand-fenris-group-ab.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandhuntersfury {
	background-image: url("../../assets/images/brands/brand-hunters-fury.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandfoundrybulwark {
	background-image: url("../../assets/images/brands/brand-foundry-bulwark.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandfutureinitiative {
	background-image: url("../../assets/images/brands/brand-future-initiative.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandstrikersbattlegear {
	background-image: url("../../assets/images/brands/brand-strikers-battlegear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandsystemcorruption {
	background-image: url("../../assets/images/brands/brand-system-corruption.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandempressinternational {
	background-image: url("../../assets/images/brands/brand-empress-international.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
