/* .weaponCard rifle {
	background-size: contain;
	background-repeat: no-repeat;
} */

.high-end-marksman-rifle {
	background-image: url("/src/assets/images/high-end-marksman-rifle.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
.exotic-marksman-rifle {
	background-image: url("/src/assets/images/exotic-marksman-rifle.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
.high-end-rifle {
	background-image: url("/src/assets/images/high-end-rifle.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
.exotic-rifle {
	background-image: url("/src/assets/images/exotic-rifle.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	padding: 0px;
}
.high-end-assault-rifle {
	background-image: url("/src/assets/images/high-end-assault-rifle.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	padding: 0px;
}
.exotic-assault-rifle {
	background-image: url("/src/assets/images/exotic-assault-rifle.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
.high-end-lmg {
	background-image: url("/src/assets/images/high-end-lmg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	padding: 0px;
}
.exotic-lmg {
	background-image: url("/src/assets/images/exotic-lmg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
.high-end-smg {
	background-image: url("/src/assets/images/high-end-smg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
.exotic-smg {
	background-image: url("/src/assets/images/exotic-smg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
.high-end-shotgun {
	background-image: url("/src/assets/images/high-end-shotgun.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
.exotic-shotgun {
	background-image: url("/src/assets/images/exotic-shotgun.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
.high-end-pistol {
	background-image: url("/src/assets/images/high-end-pistol.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
.exotic-pistol {
	background-image: url("/src/assets/images/exotic-pistol.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
	/* padding: 0px; */
}
