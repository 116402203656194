.specHeader {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: rgb(0, 0, 0);
}

.demolitionistWeapon {
	composes: specHeader;
	background-image: url("/src/assets/images/spec/demolitionist-weapon.png");
}

.firewallWeapon {
	composes: specHeader;
	background-image: url("/src/assets/images/spec/firewall-weapon.png");
}

.gunnerWeapon {
	composes: specHeader;
	background-image: url("/src/assets/images/spec/gunner-weapon.png");
}

.sharpshooterWeapon {
	composes: specHeader;
	background-image: url("/src/assets/images/spec/sharpshooter-weapon.png");
}

.survivalistWeapon {
	composes: specHeader;
	background-image: url("/src/assets/images/spec/survivalist-weapon.png");
}

.technicianWeapon {
	composes: specHeader;
	background-image: url("/src/assets/images/spec/technician-weapon.png");
}

.specIconImage {
	z-index: 1;
	position: absolute;
	height: 75%;
	top: 0.5rem;
	left: 0.5rem;
}

.specAmmoImage {
	z-index: 1;
	position: absolute;
	height: 75%;
	top: 0.5rem;
	right: 0.5rem;
}

.weaponTitle {
	color: rgb(255, 255, 255);
	z-index: 1;
	position: absolute;
	height: 50%;
	top: -1rem;
	left: 6.4rem;
}

.gearTitle {
	color: rgb(255, 255, 255);
	z-index: 1;
	position: absolute;
	height: 50%;
	top: -1rem;
	left: 6.4rem;
}

.weaponExpertiseLabel {
	color: rgb(255, 255, 255);
	z-index: 1;
	position: absolute;
	height: 50%;
	top: 2rem;
	right: 2.5rem;
}

.weaponExpertiseImage {
	z-index: 1;
	position: absolute;
	height: 50%;
	top: 2.3rem;
	right: 5px;
}

.weaponTalentImage {
	z-index: 1;
	position: absolute;
	height: 50%;
	top: 0.5rem;
	right: 5px;
}

.weaponTalentLabel {
	color: rgb(255, 255, 255);
	z-index: 1;
	position: absolute;
	height: 50%;
	top: 0.4rem;
	right: 2.5rem;
}

.gearExpertiseLabel {
	color: rgb(255, 255, 255);
	z-index: 1;
	position: absolute;
	height: 50%;
	top: 2rem;
	right: 2.5rem;
}

.gearExpertiseImage {
	z-index: 1;
	position: absolute;
	height: 30px;
	top: -0.1rem;
	right: 1.6rem;
}

.gearSlotImage {
	z-index: 1;
	position: absolute;
	height: 30px;
	top: -0.1rem;
	right: 0rem;
}

.gearCoreLabel {
	z-index: 1;
	position: absolute;
	top: -0.1rem;
	left: 6.4rem;
}

.gearCoreImage {
	z-index: 1;
	position: absolute;
	height: 15px;
	top: 0.9rem;
	left: 5.2rem;
}

.gearAttribute1Label {
	z-index: 1;
	position: absolute;
	top: 0.9rem;
	left: 6.4rem;
}

.gearAttribute1Image {
	z-index: 1;
	position: absolute;
	height: 15px;
	top: 1.8rem;
	left: 5.2rem;
}

.gearAttribute2Label {
	z-index: 1;
	position: absolute;
	top: 1.9rem;
	left: 6.4rem;
}

.gearAttribute2Image {
	z-index: 1;
	position: absolute;
	height: 15px;
	top: 2.8rem;
	left: 5.2rem;
}

.gearModLabel {
	z-index: 1;
	position: absolute;
	top: 2.9rem;
	left: 6.4rem;
}

.gearModImage {
	z-index: 1;
	position: absolute;
	height: 15px;
	top: 3.8rem;
	left: 5.2rem;
}

.gearTalentLabel {
	color: rgb(255, 255, 255);
	z-index: 1;
	position: absolute;
	height: 50%;
	top: 3rem;
	right: 2.5rem;
}

.gearTalentImage {
	z-index: 1;
	position: absolute;
	height: 30px;
	top: 3.2rem;
	right: 5px;
}

.high-end-marksman-rifle {
	background-image: url("/src/assets/images/high-end-marksman-rifle.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.high-end-rifle {
	background-image: url("/src/assets/images/high-end-rifle.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.high-end-assault-rifle {
	background-image: url("/src/assets/images/high-end-assault-rifle.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.high-end-lmg {
	background-image: url("/src/assets/images/high-end-lmg.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.high-end-smg {
	background-image: url("/src/assets/images/high-end-smg.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.high-end-shotgun {
	background-image: url("/src/assets/images/high-end-shotgun.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.high-end-pistol {
	background-image: url("/src/assets/images/high-end-pistol.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.named-marksman-rifle {
	background-image: url("/src/assets/images/high-end-marksman-rifle.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.named-rifle {
	background-image: url("/src/assets/images/high-end-rifle.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.named-assault-rifle {
	background-image: url("/src/assets/images/high-end-assault-rifle.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.named-lmg {
	background-image: url("/src/assets/images/high-end-lmg.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.named-smg {
	background-image: url("/src/assets/images/high-end-smg.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.named-shotgun {
	background-image: url("/src/assets/images/high-end-shotgun.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.named-pistol {
	background-image: url("/src/assets/images/high-end-pistol.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.exotic-marksman-rifle {
	background-image: url("/src/assets/images/exotic-marksman-rifle.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.exotic-rifle {
	background-image: url("/src/assets/images/exotic-rifle.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.exotic-assault-rifle {
	background-image: url("/src/assets/images/exotic-assault-rifle.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.exotic-lmg {
	background-image: url("/src/assets/images/exotic-lmg.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.exotic-smg {
	background-image: url("/src/assets/images/exotic-smg.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.exotic-shotgun {
	background-image: url("/src/assets/images/exotic-shotgun.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.exotic-pistol {
	background-image: url("/src/assets/images/exotic-pistol.png");
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

/* BRANDS */
.brand-header {
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand-overlord-armaments {
	composes: brand-header;
	background-image: url("/src/assets/images/brands/brand-overlord-armaments.png");
}
.brandaceseights {
	background-image: url("/src/assets/images/brands/brand-aces-eights.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandgilaguard {
	background-image: url("/src/assets/images/brands/brand-gila-guard.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandsokolovconcern {
	background-image: url("/src/assets/images/brands/brand-sokolov-concern.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandtruepatriot {
	background-image: url("/src/assets/images/brands/brand-true-patriot.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandwalkerharrisco {
	background-image: url("/src/assets/images/brands/brand-walker-harris-co.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandrigger {
	background-image: url("/src/assets/images/brands/brand-rigger.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandbelstonearmory {
	background-image: url("/src/assets/images/brands/brand-belstone-armory.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandongoingdirective {
	background-image: url("/src/assets/images/brands/brand-ongoing-directive.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandyaahlgear {
	background-image: url("/src/assets/images/brands/brand-yaahl-gear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandnegotiatorsdilemma {
	background-image: url("/src/assets/images/brands/brand-negotiators-dilemma.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandalpssummitarmaments {
	background-image: url("/src/assets/images/brands/brand-alps-summit-armaments.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandbadgertuff {
	background-image: url("/src/assets/images/brands/brand-badger-tuff.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandgruposombrasa {
	background-image: url("/src/assets/images/brands/brand-grupo-sombra-sa.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandmurakamiindustries {
	background-image: url("/src/assets/images/brands/brand-murakami-industries.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandcrafted {
	background-image: url("/src/assets/images/brands/brand-crafted.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandceskavyrobasro {
	background-image: url("/src/assets/images/brands/brand-ceska-vyroba-sro.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandpetrovdefensegroup {
	background-image: url("/src/assets/images/brands/brand-petrov-defense-group.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandrichterkaisergmbh {
	background-image: url("/src/assets/images/brands/brand-richter-kaiser-gmbh.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandhardwired {
	background-image: url("/src/assets/images/brands/brand-hard-wired.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandeclipseprotocol {
	background-image: url("/src/assets/images/brands/brand-eclipse-protocol.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandairaldiholdings {
	background-image: url("/src/assets/images/brands/brand-airaldi-holdings.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandhana-ucorporation {
	background-image: url("/src/assets/images/brands/brand-hana-u-corporation.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandprovidencedefense {
	background-image: url("/src/assets/images/brands/brand-providence-defense.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandwyvernwear {
	background-image: url("/src/assets/images/brands/brand-wyvern-wear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandtipofthespear {
	background-image: url("/src/assets/images/brands/brand-tip-of-the-spear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brand511tactical {
	background-image: url("/src/assets/images/brands/brand-511-tactical.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.branddouglasharding {
	height: 75px;
	background-image: url("/src/assets/images/brands/brand-douglas-harding.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandgolangearltd {
	background-image: url("/src/assets/images/brands/brand-golan-gear-ltd.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
/* .brandexotic {
	background-image: url("/src/assets/images/brands/brand-exotic.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
} */
.brandexoticdodgecitygunslingersholster {
	background-image: url("/src/assets/images/brands/brand-exotic-dodge-city-gunslingers-holster.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandchinalightindustriescorporation {
	background-image: url("/src/assets/images/brands/brand-china-light-industries-corporation.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandfenrisgroupab {
	background-image: url("/src/assets/images/brands/brand-fenris-group-ab.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandhuntersfury {
	background-image: url("/src/assets/images/brands/brand-hunters-fury.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandfoundrybulwark {
	background-image: url("/src/assets/images/brands/brand-foundry-bulwark.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandfutureinitiative {
	background-image: url("/src/assets/images/brands/brand-future-initiative.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandstrikersbattlegear {
	background-image: url("/src/assets/images/brands/brand-strikers-battlegear.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandsystemcorruption {
	background-image: url("/src/assets/images/brands/brand-system-corruption.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}
.brandempressinternational {
	background-image: url("/src/assets/images/brands/brand-empress-international.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: black;
}

.loaderWrapper {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #242f3f;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loader {
	display: inline-block;
	width: 30px;
	height: 30px;
	position: relative;
	border: 4px solid #fff;
	animation: loader 2s infinite ease;
}
.loader-inner {
	vertical-align: top;
	display: inline-block;
	width: 100%;
	background-color: #fff;
	animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(180deg);
	}
	50% {
		transform: rotate(180deg);
	}
	75% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes loader-inner {
	0% {
		height: 0%;
	}
	25% {
		height: 0%;
	}
	50% {
		height: 100%;
	}
	75% {
		height: 100%;
	}
	100% {
		height: 0%;
	}
}
