.mask100 {
	width: 56px; /* 25px */
	height: 54px; /* 23px */
}
.gear75 {
	width: 42px; /* 25px */
	height: 40px; /* 23px */
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.gear25 {
	width: 25px; /* 25px */
	height: 23px; /* 23px */
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.mask25 {
	width: 25px; /* 25px */
	height: 23px; /* 23px */
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.backpack {
	width: 56px;
	height: 54px;
}
.vest {
	width: 56px;
	height: 54px;
}
.gloves {
	width: 56px;
	height: 54px;
}
.holster {
	width: 56px;
	height: 54px;
}
.kneepads {
	width: 56px;
	height: 54px;
}
